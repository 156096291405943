/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const isNullItem = (item: any): boolean => {
	if (typeof item === 'number' || typeof item === 'boolean') {
		return false;
	}

	if (typeof item === 'undefined' || item === null) {
		return true;
	}

	if (typeof item === 'string') {
		if (item === '') {
			return true;
		}
		return false;
	}

	return true;
};

export default isNullItem;
