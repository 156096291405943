/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { Switch, Route } from 'react-router-dom';
import BixbySidenav from './BixbySidenav';
import BixbyNavbar from './BixbyNavbar';
import BixbyFetch from './BixbyFetch/BixbyFetch';
import ReportList from './CausalImpact/ReportList';
import NewReport from './CausalImpact/NewReport';
import ViewReport from './CausalImpact/ViewReport';
import ClientView from './Clients/ClientView';

const BixbyDashboard: React.FC = () => {
	return (
		<div id='bixby-wrapper'>
			<BixbySidenav />
			<div id='bixby-dashboard'>
				<BixbyNavbar />
				<div id='bixby-content'>
					<Switch>
						<Route
							path='/'
							exact
							render={() => (
								<NonIdealState
									icon='crown'
									title='Bixby 2'
									description='Version 0.0.?'
								/>
							)}
						/>
						<Route path='/bixby-fetch' component={BixbyFetch} />
						<Route
							exact
							path='/causal-impact'
							render={(props) => (
								// eslint-disable-next-line react/prop-types
								<ReportList {...props} />
							)}
						/>
						<Route exact path='/causal-impact/new' component={NewReport} />
						<Route exact path='/causal-impact/:id' component={ViewReport} />
						<Route exact path='/client/:id/:route?' component={ClientView} />
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default BixbyDashboard;
