/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as PapaParse from 'papaparse';
import { FileInput } from '@blueprintjs/core';

export interface IFileInfo {
	name: string;
	size: number;
	type: string;
}

export interface CSVReaderProps {
	accept?: string;
	fileEncoding?: string;
	inputId?: string;
	inputStyle?: object;
	textString?: string;
	onError?: (error: Error) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFileLoaded: (data: Array<any>, fileInfo: IFileInfo) => any;
	parserOptions?: PapaParse.ParseConfig;
	disabled?: boolean;
}

const CSVReader: React.FC<CSVReaderProps> = ({
	fileEncoding = 'UTF-8',
	onError,
	onFileLoaded,
	textString,
	parserOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
		transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
	} as PapaParse.ParseConfig,
}) => {
	const handleChangeFile = (event: React.FormEvent<HTMLInputElement>) => {
		if (
			event.currentTarget.files !== null &&
			event.currentTarget.files.length > 0
		) {
			const reader: FileReader = new FileReader();
			const { files } = event.currentTarget;
			if (files.length > 0) {
				const fileInfo: IFileInfo = {
					name: files[0].name,
					size: files[0].size,
					type: files[0].type,
				};

				reader.onload = () => {
					const csvData = PapaParse.parse(
						reader.result as string,
						Object.assign(parserOptions, {
							error: onError,
							encoding: fileEncoding,
						})
					);
					onFileLoaded(csvData?.data ?? [], fileInfo);
				};

				reader.readAsText(files[0], fileEncoding);
			}
		}
	};

	return (
		<FileInput onInputChange={(e) => handleChangeFile(e)} text={textString} />
	);
};

CSVReader.propTypes = {
	fileEncoding: PropTypes.string,
	onError: PropTypes.func,
	onFileLoaded: PropTypes.func.isRequired,
	parserOptions: PropTypes.object,
	textString: PropTypes.string,
};

export default CSVReader;
