class ReportData {
	date: string;

	metric_data: string;

	constructor(_date: string, _metric_data: string) {
		this.date = _date;
		this.metric_data = _metric_data;
	}
}

export default ReportData;
