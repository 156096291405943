import React from 'react';
import {
	Callout,
	Card,
	Elevation,
	Button,
	Collapse,
	InputGroup,
	FormGroup,
	H5,
	Toaster,
	Position,
	Intent,
} from '@blueprintjs/core';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { CSVReader } from 'react-papaparse';
import axios from 'axios';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		gapi: any;
	}
}

const bixbyFetchToaster = Toaster.create({
	className: 'bf-toaster',
	position: Position.TOP,
});

type BixbyFetchState = {
	instructionsOpen: boolean;
	accessToken: string;
	assistantQuestions: string[];
	bixbyFetchRequested: boolean;
	spreadsheet: boolean;
	newQuestion: string;
};

class BixbyFetch extends React.Component<WithAuth0Props, BixbyFetchState> {
	private baseApiURL: string = process.env
		.REACT_APP_BIXBY_FETCH_API_URL as string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private csvInputRef = React.createRef<any>();

	constructor(props: WithAuth0Props) {
		super(props);
		this.state = {
			instructionsOpen: false,
			accessToken: '',
			assistantQuestions: [],
			bixbyFetchRequested: false,
			spreadsheet: false,
			newQuestion: '',
		};
	}

	componentDidMount() {
		const ga =
			window.gapi && window.gapi.auth2
				? window.gapi.auth2.getAuthInstance()
				: null;
		if (!ga) this.createScript();
		// const { user } = this.props.auth0;
	}

	private initGapi = () => {
		const g = window.gapi;

		g.load('auth2', () => {
			g.auth2.init({
				client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string,
				scope:
					'profile email openid https://www.googleapis.com/auth/spreadsheets',
			});
		});
	};

	private createScript = () => {
		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/platform.js';
		script.async = true;
		script.onload = this.initGapi;
		document.body.appendChild(script);
	};

	private signIn = () => {
		const ga = window.gapi.auth2.getAuthInstance();
		ga.signIn();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ga.grantOfflineAccess().then((data: any) => {
			this.setState({
				accessToken: data.code,
				spreadsheet: true,
			});
		});
	};

	private handleNewQuestionChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.setState({
			newQuestion: e.target.value,
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private onFileLoaded = (data: any, file?: any) => {
		if (file) {
			if (data.length !== 0) {
				const slicedData = data.slice(1);
				const questions: string[] = [];

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				slicedData.forEach((d: any) => {
					const question = d.data[0];
					questions.push(question);
				});

				this.setState({
					assistantQuestions: questions,
				});
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private loadFile = (e: any) => {
		this.csvInputRef.current.open(e);
	};

	private addToQuestions = () => {
		const { newQuestion, assistantQuestions } = this.state;

		if (newQuestion !== '') {
			const currentQuestions = assistantQuestions;
			currentQuestions.push(newQuestion);

			this.setState({
				assistantQuestions: currentQuestions,
				newQuestion: '',
			});
		}
	};

	private makeBixbyFetchRequest = () => {
		this.setState({
			bixbyFetchRequested: true,
		});

		const { auth0 } = this.props;
		const { assistantQuestions, accessToken } = this.state;

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			axios
				.post(
					`${this.baseApiURL}/bixby_fetch`,
					{
						questions: assistantQuestions,
						access_token: accessToken,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then(() => {
					this.makeToast(
						'Request successful! Please wait a couple of minutes for your document to arrive at your Google drive!',
						Intent.SUCCESS,
						'tick'
					);
					this.setState({
						assistantQuestions: [],
						bixbyFetchRequested: false,
						newQuestion: '',
					});
				})
				.catch(() => {
					this.makeToast(
						'There was an error processing your request. Please try again later...',
						Intent.DANGER,
						'cross'
					);
					this.setState({
						bixbyFetchRequested: false,
					});
				});
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private makeToast = (msg: string, int: Intent, icon?: any) => {
		bixbyFetchToaster.show({
			message: msg,
			timeout: 3000,
			intent: int,
			icon,
		});
	};

	private handleInstructionsClick = () => {
		const { instructionsOpen } = this.state;

		this.setState({
			instructionsOpen: !instructionsOpen,
		});
	};

	render() {
		const {
			assistantQuestions,
			instructionsOpen,
			spreadsheet,
			bixbyFetchRequested,
			newQuestion,
		} = this.state;

		return (
			<Card elevation={Elevation.TWO}>
				<div style={{ display: 'none' }}>
					<CSVReader
						ref={this.csvInputRef}
						onFileLoad={this.onFileLoaded}
						config={{ header: false }}
						noClick
						noDrag
						noProgressBar
					>
						<></>
					</CSVReader>
				</div>
				<Callout
					title='Limited API Requests'
					intent='warning'
					style={{ marginBottom: '15px' }}
				>
					Due to a limited amount of API requests the maximum amount of
					questions you may ask is 60.
				</Callout>
				<Button
					onClick={this.handleInstructionsClick}
					style={{ marginBottom: '10px' }}
				>
					{instructionsOpen ? 'Close' : 'Open'} Instructions
				</Button>
				<Collapse isOpen={instructionsOpen}>
					<Card style={{ marginBottom: '10px' }}>
						<ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
							<li>
								1. Click on the &quot;Load CSV&quot; button and select the file
								you exported from Google Sheets.
							</li>
							<li>
								2. Make sure all of the questions you wish to ask the Google
								Assistant are on the list below.
							</li>
							<li>
								3. Ensure that you have allowed spreadsheet access by clicking
								the &quot;Allow Spreadsheet Access&quot; button next to the
								&quot;Load CSV&quot; button.
							</li>
							<li>
								4. Once you&apos;ve confirmed everything is correct click on the
								&quot;Confirm &amp; Fetch!&quot; button below the list.
							</li>
							<li>
								5. Once you see the success notification wait a little while and
								your new spreadsheet will appear in your Drive.
							</li>
						</ul>
					</Card>
				</Collapse>
				<Button onClick={this.loadFile}>Load CSV</Button>
				<Button
					style={{ marginLeft: '10px', verticalAlign: 'bottom' }}
					onClick={this.signIn}
					disabled={spreadsheet}
				>
					{spreadsheet
						? 'Spreadsheet Access Allowed'
						: 'Allow Spreadsheet Access'}
				</Button>
				<FormGroup inline style={{ marginTop: '10px' }}>
					<InputGroup
						onChange={this.handleNewQuestionChange}
						className='bixby-fetch-question-input'
						type='text'
						placeholder='Enter a question...'
						value={newQuestion}
					/>
					<Button onClick={this.addToQuestions}>Add Question</Button>
				</FormGroup>
				<Card style={{ marginBottom: '10px' }}>
					<H5>Questions: {assistantQuestions.length}</H5>
					<ul style={{ listStyle: 'none' }}>
						{assistantQuestions.map((d) => (
							// eslint-disable-next-line react/no-array-index-key
							<li key={d}>{d}</li>
						))}
					</ul>
				</Card>
				<Button
					onClick={this.makeBixbyFetchRequest}
					disabled={
						!(
							spreadsheet &&
							assistantQuestions.length > 0 &&
							!bixbyFetchRequested
						)
					}
				>
					{bixbyFetchRequested ? 'Fetching...' : 'Confirm & Fetch'}
				</Button>
			</Card>
		);
	}
}

export default withAuth0(BixbyFetch);
