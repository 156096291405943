import React from 'react';
import { HTMLTable, H4, Button, Tag, Icon } from '@blueprintjs/core';

const ClientContracts: React.FunctionComponent = () => (
	<div className='client-tabbed-content'>
		<div className='client-scope-header'>
			<H4>Contracts</H4>
			<Button text='Contract' icon='add' intent='primary' />
		</div>
		<HTMLTable className='client-scope-table'>
			<thead>
				<tr>
					<th>Name</th>
					<th>Start</th>
					<th>End/Renew</th>
					<th>Renew Term</th>
					<th>Notes</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>2016 Signed Agreement</td>
					<td>10/19/2016</td>
					<td>10/19/2020</td>
					<td>Yearly</td>
					<td>
						<Icon icon='clipboard' />
					</td>
					<td>
						<Tag key='active' intent='success' className='status-active'>
							Active
						</Tag>
					</td>
				</tr>
				<tr className='contract-inactive'>
					<td>Digital Intelligence - Signed</td>
					<td>07/11/2018</td>
					<td>-</td>
					<td>None</td>
					<td>
						<Icon icon='clipboard' />
					</td>
					<td>Inactive</td>
				</tr>
				<tr>
					<td>2018 Agreement - Adjusted Content Marketing</td>
					<td>12/10/2018</td>
					<td>-</td>
					<td>None</td>
					<td>
						<Icon icon='clipboard' />
					</td>
					<td>
						<Tag key='active' intent='success' className='status-active'>
							Active
						</Tag>
					</td>
				</tr>
				<tr>
					<td>Strategic Support</td>
					<td>03/25/2019</td>
					<td>12/25/2019</td>
					<td>None</td>
					<td>
						<Icon icon='clipboard' />
					</td>
					<td>
						<Tag key='active' intent='success' className='status-active'>
							Active
						</Tag>
					</td>
				</tr>
			</tbody>
		</HTMLTable>
	</div>
);

export default ClientContracts;
