/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { WidgetProps } from '@rjsf/core';

export interface TextWidgetProps extends WidgetProps {
	type?: string;
}

const TextWidget = ({
	id,
	required,
	readonly,
	disabled,
	type,
	value,
	autofocus,
	schema,
	options,
	rawErrors = [],
	onChange,
	onBlur,
	onFocus,
}: TextWidgetProps) => {
	return (
		<FormGroup className='input-container'>
			<InputGroup
				id={id}
				autoFocus={autofocus}
				required={required}
				disabled={disabled}
				readOnly={readonly}
				className={rawErrors.length > 0 ? 'is-invalid' : ''}
				list={schema.examples ? `examples_${id}` : undefined}
				type={type || (schema.type as string)}
				value={value || value === 0 ? value : ''}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					onChange(
						event.target.value === '' ? options.emptyValue : event.target.value
					);
				}}
				onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
					onBlur(id, event.target.value);
				}}
				onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
					onFocus(id, event.target.value);
				}}
			/>
		</FormGroup>
	);
};

export default TextWidget;
