/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent } from 'react';
import { withTheme, FormProps } from '@rjsf/core';

import Theme from '../Theme';

const Form:
	| React.ComponentClass<FormProps<any>>
	| FunctionComponent<FormProps<any>> = withTheme(Theme);

export default Form;
