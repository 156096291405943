import React, { Component } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabId, Tag, Spinner } from '@blueprintjs/core';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import { RouteComponentProps } from 'react-router-dom';
import logo from '../assets/images/spacex.jpg';
import ClientServices from './ClientServices';
import ClientContracts from './ClientContracts';
import ClientSCOPE from './ClientSCOPE';
import ClientExternalAccounts from './ClientExternalAccounts';

interface ClientViewState {
	clientId: string;
	clientName: string;
	navbarTabId: TabId;
	loading: boolean;
}

type ViewReportParams = { id: string; route: string };
type RouteComponentWithAuth0Props = RouteComponentProps<ViewReportParams> &
	WithAuth0Props;

class ClientView extends Component<
	RouteComponentWithAuth0Props,
	ClientViewState
> {
	private baseApiURL: string = process.env.REACT_APP_BIXBY_2_API_URL as string;

	constructor(props: RouteComponentWithAuth0Props) {
		super(props);
		this.state = {
			clientId: '',
			clientName: '',
			navbarTabId: 'profile',
			loading: true,
		};
	}

	componentDidMount() {
		const { match, auth0 } = this.props;
		const { id, route } = match.params;
		auth0
			.getAccessTokenSilently({ ignoreCache: true })
			.then(() => {
				axios
					.get(`${this.baseApiURL}/clients/${id}/`, {
						headers: {
							Authorization: `Token f2e20082a7fad5836dc33de7e19451da02475d80`, // this is here temporarily, local token
						},
					})
					.then((resp) => {
						this.setState({
							clientId: id,
							clientName: resp.data.name,
							loading: false,
						});
					})
					.catch(() => {});
			})
			.catch(() => {});

		if (route !== undefined) {
			this.setState({
				navbarTabId: route,
			});
		}
	}

	private handleNavbarTabChange = (navbarTabId: TabId) =>
		this.setState({ navbarTabId });

	render() {
		const { navbarTabId, clientId, clientName, loading } = this.state;
		if (loading) {
			return <Spinner />;
		}

		return (
			<div id='client-area'>
				<div
					id='client-side-bar'
					style={{
						background: `linear-gradient(180deg, #A0D8E2 80px, white 81px 100%)`,
					}}
				>
					<div className='client-logo'>
						<img src={logo} alt='Client Logo' />
					</div>
					<span className='client-name'>{clientName}</span>
					<span className='client-cid'>CID#{clientId}</span>
					<span className='client-since'>Client Since: Jan 06, 2020</span>
					<div className='client-scores'>
						<div className='score'>
							<span className='green-score'>Green</span>
							Health
						</div>
						<div className='score'>
							<span className='green-score'>5/5</span>
							SCOPE
						</div>
						<div className='score'>
							<span className='green-score'>9/10</span>
							Delighted
						</div>
					</div>
					<div className='client-wpromote-team'>
						<span className='section-title'>Wpromote Team</span>
						<div className='wpromote-team-data'>
							<table className='wpromote-team-data-table'>
								<tr>
									<td className='team-role'>Lead Director</td>
									<td>John Smith</td>
								</tr>
								<tr>
									<td className='team-role'>Team Lead</td>
									<td>Jane Smith</td>
								</tr>
								<tr>
									<td className='team-role'>Sales Person</td>
									<td>Matthew Smith</td>
								</tr>
								<tr>
									<td className='team-role'>Head of Industry</td>
									<td>Susan Smith</td>
								</tr>
							</table>
						</div>
					</div>
					<div className='client-contact'>
						<span className='section-title'>Client Contact</span>
						<div className='client-contact-data'>
							<span className='client-contact-name'>Elon Musk</span>
							<span className='client-contact-title'>
								Chief Executive Officer
							</span>
							<span className='client-contact-email'>elon@spacex.com</span>
							<span className='client-contact-phonenum'>
								310.363.6000 Ext 1337
							</span>
						</div>
					</div>
					<div className='client-tags'>
						<span className='section-title'>Tags</span>
						<div className='tag-data'>
							<Tag key='space' round interactive>
								Space
							</Tag>
							<Tag key='rockets' round interactive>
								Rockets
							</Tag>
							<Tag key='mars' round interactive>
								Mars
							</Tag>
							<Tag key='falcon' round interactive>
								Falcon
							</Tag>
							<Tag key='dragon' round interactive>
								Dragon
							</Tag>
							<Tag key='starship' round interactive>
								Starship
							</Tag>
						</div>
					</div>
				</div>
				<div id='client-content'>
					<Tabs
						animate
						id='client-tabbed-content'
						key='horizontal'
						renderActiveTabPanelOnly={false}
						vertical={false}
						selectedTabId={navbarTabId}
						onChange={this.handleNavbarTabChange}
						className='client-content-tabs'
						large
					>
						<Tab
							id='profile'
							title='Profile'
							panel={<div>Client Profile</div>}
						/>
						<Tab
							id='overview'
							title='Overview'
							panel={<div>Client Overview</div>}
						/>
						<Tab id='services' title='Services' panel={<ClientServices />} />
						<Tab id='contracts' title='Contracts' panel={<ClientContracts />} />
						<Tab id='scope' title='SCOPE' panel={<ClientSCOPE />} />
						<Tab
							id='delighted'
							title='Delighted'
							panel={<div>Client Delighted</div>}
						/>
						<Tab id='notes' title='Notes' panel={<div>Client Notes</div>} />
						<Tab
							id='external'
							title='External Accounts'
							panel={<ClientExternalAccounts clientId={clientId} />}
						/>
					</Tabs>
				</div>
			</div>
		);
	}
}

export default withAuth0(ClientView);
