import React, { Component } from 'react';
import axios from 'axios';
import {
	TextArea,
	Card,
	Elevation,
	H4,
	Button,
	H5,
	HTMLTable,
	Spinner,
} from '@blueprintjs/core';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { RouteComponentProps, Link } from 'react-router-dom';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

interface ViewReportState {
	loadingData: boolean;
	reportName: string;
	reportSummary: string;
	reportImage: string;
	reportType: string;
	requester: string;
	dimension: string;
	metric: string;
	startDate: string;
	endDate: string;
	interventionDate: string;
	runDate: string;
}

type ViewReportParams = { id: string };
type RouteComponentWithAuth0Props = RouteComponentProps<ViewReportParams> &
	WithAuth0Props;

const reportTypes: { [key: string]: string } = {
	csv: 'CSV',
	ga: 'Google Analytics',
};

class ViewReport extends Component<
	RouteComponentWithAuth0Props,
	ViewReportState
> {
	private baseApiURL: string = process.env
		.REACT_APP_CAUSAL_IMPACT_API_URL as string;

	constructor(props: RouteComponentWithAuth0Props) {
		super(props);
		this.state = {
			loadingData: true,
			reportName: '',
			reportSummary: '',
			reportImage: '',
			reportType: '',
			requester: '',
			dimension: '',
			metric: '',
			startDate: '',
			endDate: '',
			interventionDate: '',
			runDate: '',
		};
	}

	componentDidMount() {
		const { match } = this.props;
		const { id } = match.params;

		const { auth0 } = this.props;

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			axios
				.post(
					`${this.baseApiURL}/causal-impact/report`,
					{
						report_id: id,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then((resp) => {
					this.setState({
						loadingData: false,
						reportName: resp.data.report_name,
						reportSummary: resp.data.report_summary,
						reportImage: resp.data.data_graph_image,
						reportType: resp.data.report_type,
						requester: resp.data.requester,
						dimension: resp.data.dimension,
						metric: resp.data.metric,
						startDate: resp.data.start_date,
						endDate: resp.data.end_date,
						interventionDate: resp.data.intervention_date,
						runDate: resp.data.date,
					});
				})
				.catch(() => {});
		});
	}

	private downloadReport = () => {
		const { reportName, reportSummary, reportImage } = this.state;
		const zippedFile = new JSZip();
		zippedFile.file('report.txt', reportSummary);
		zippedFile.file('graph.png', `${reportImage}`, { base64: true });
		zippedFile.generateAsync({ type: 'blob' }).then((content) => {
			FileSaver.saveAs(content, `${reportName}.zip`);
		});
	};

	render() {
		const {
			loadingData,
			reportName,
			reportSummary,
			reportImage,
			reportType,
			requester,
			metric,
			dimension,
			startDate,
			endDate,
			interventionDate,
			runDate,
		} = this.state;

		if (loadingData) {
			return (
				<div
					style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
				>
					<div style={{ margin: 'auto' }}>
						<Spinner intent='primary' />
					</div>
				</div>
			);
		}

		return (
			<div id='causal-impact-container'>
				<div id='causal-impact-header'>
					<H4>Causal Impact Report: {reportName}</H4>
					<div>
						<Link to='/causal-impact'>
							<Button icon='arrow-left' intent='none'>
								Back To Reports List
							</Button>
						</Link>
						<Button
							icon='download'
							intent='primary'
							style={{ marginLeft: '10px' }}
							onClick={() => this.downloadReport()}
						>
							Download Report Data
						</Button>
					</div>
				</div>
				<div className='causal-impact-report-view-row'>
					<Card className='ci-report-view-row-child' elevation={Elevation.TWO}>
						<H5>Causal Impact Summary</H5>
						<TextArea className='report-view-summary' value={reportSummary} />
					</Card>
					<Card className='ci-report-view-row-child' elevation={Elevation.TWO}>
						<H5>Causal Impact Request Data</H5>
						<HTMLTable
							striped
							bordered
							className='ci-report-view-request-data-table'
						>
							<tbody>
								<tr>
									<td className='metadata-title'>Report Name</td>
									<td>{reportName}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Report Type</td>
									<td>{reportTypes[reportType]}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Requester</td>
									<td>{requester}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Dimension</td>
									<td>{dimension}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Metric</td>
									<td>{metric}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Impact Start Date</td>
									<td>{startDate}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Impact End Date</td>
									<td>{endDate}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Impact Intervention Date</td>
									<td>{interventionDate}</td>
								</tr>
								<tr>
									<td className='metadata-title'>Report Run Date</td>
									<td>{moment(runDate, 'x').format('MMMM DD YYYY hh:mma')}</td>
								</tr>
							</tbody>
						</HTMLTable>
					</Card>
				</div>
				<div className='causal-impact-report-view-row'>
					<Card className='ci-report-view-row-child' elevation={Elevation.TWO}>
						<H5>Causal Impact Graphs</H5>
						<div className='ci-report-view' style={{ display: 'flex' }}>
							{reportImage && (
								<img
									className='ci-report-view-image'
									alt='Causal Inferencing Report'
									src={`data:image/png;base64,${reportImage}`}
								/>
							)}
						</div>
					</Card>
				</div>
			</div>
		);
	}
}

export default withAuth0(ViewReport);
