import React from 'react';
import { FieldProps } from '@rjsf/core';
import { H5 } from '@blueprintjs/core';

export interface TitleFieldProps extends Partial<FieldProps> {
	title: string;
}

const TitleField: React.FC = ({ title }: Partial<FieldProps>) => (
	<div className='rjsf-title-container'>
		<H5>{title}</H5>
	</div>
);

export default TitleField;
