import React from 'react';
import { Tooltip } from '@blueprintjs/core';
import portrait from '../assets/images/manager-portrait.jpg';

interface ManagerIconProps {
	state: string;
	name: string;
}

const statusColors: { [key: string]: string } = {
	green: '#2fb29f',
	red: '#f25649',
	yellow: '#f8da6c',
	blue: '#54c7df',
};

const ManagerIcon: React.FunctionComponent<ManagerIconProps> = ({
	state,
	name,
}: ManagerIconProps): JSX.Element => (
	<Tooltip content={name}>
		<div
			className='manager-icon'
			style={{
				border: `3px solid ${statusColors[state]}`,
				borderRadius: '15px',
				backgroundImage: `url(${portrait})`,
				backgroundSize: 'cover',
				marginRight: '8px',
			}}
		/>
	</Tooltip>
);

export default ManagerIcon;
