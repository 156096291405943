/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import React from 'react';

import { FormGroup, HTMLSelect } from '@blueprintjs/core';

import { WidgetProps, utils } from '@rjsf/core';

const { asNumber, guessType } = utils;

const nums = new Set(['number', 'integer']);

const processValue = (schema: any, value: any) => {
	const { type, items } = schema;
	if (value === '') {
		return undefined;
	}

	if (type === 'array' && items && nums.has(items.type)) {
		return value.map(asNumber);
	}

	if (type === 'boolean') {
		return value === 'true';
	}

	if (type === 'number') {
		return asNumber(value);
	}

	if (schema.enum) {
		if (schema.enum.every((x: any) => guessType(x) === 'number')) {
			return asNumber(value);
		}

		if (schema.enum.every((x: any) => guessType(x) === 'boolean')) {
			return value === 'true';
		}
	}

	return value;
};

const SelectWidget = ({
	schema,
	id,
	options,
	required,
	disabled,
	value,
	autofocus,
	rawErrors = [],
	onChange,
}: WidgetProps) => {
	const { enumOptions, enumDisabled } = options;

	return (
		<FormGroup>
			<HTMLSelect
				id={id}
				value={typeof value === 'undefined' ? '' : value}
				required={required}
				disabled={disabled}
				autoFocus={autofocus}
				className={rawErrors.length > 0 ? 'is-invalid' : ''}
				onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
					onChange(processValue(schema, event.currentTarget.value));
				}}
			>
				{(enumOptions as any).map(({ value, label }: any, i: number) => {
					const disabled: any =
						Array.isArray(enumDisabled) &&
						(enumDisabled as any).indexOf(value) !== -1;
					return (
						<option key={i} id={label} value={value} disabled={disabled}>
							{label}
						</option>
					);
				})}
			</HTMLSelect>
		</FormGroup>
	);
};

export default SelectWidget;
