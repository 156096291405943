/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import logo from './assets/images/wpromote.png';

const BixbySidenav: React.FC = () => {
	const menuHistory = useHistory();

	return (
		<div id='bixby-sidenav'>
			<div id='wpromote-logo'>
				<img
					src={logo}
					alt='Wpromote Logo'
					role='button'
					onClick={() => {
						menuHistory.push('/');
					}}
					style={{ cursor: 'pointer' }}
				/>
			</div>
			<div id='bixby-sidenav-nav'>
				<Button
					icon='home'
					className='sidenav-button'
					minimal
					onClick={() => {
						menuHistory.push('/');
					}}
				/>
				<Button
					icon='people'
					className='sidenav-button'
					minimal
					onClick={() => {
						menuHistory.push('/client/1337');
					}}
				/>
				<Button
					icon='timeline-line-chart'
					className='sidenav-button'
					minimal
					onClick={() => {
						menuHistory.push('/causal-impact');
					}}
				/>
				<Button
					icon='geosearch'
					className='sidenav-button'
					minimal
					onClick={() => {
						menuHistory.push('/bixby-fetch');
					}}
				/>
				<Button icon='cog' className='sidenav-button' minimal disabled />
			</div>
		</div>
	);
};

export default BixbySidenav;
