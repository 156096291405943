import React, { Component } from 'react';
import axios from 'axios';
import {
	Button,
	Card,
	Elevation,
	HTMLTable,
	H4,
	Callout,
	NonIdealState,
	Spinner,
} from '@blueprintjs/core';
import moment from 'moment';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import Report from './Report';

type ReportListState = {
	reports: Report[];
	isLoading: boolean;
	redirect: boolean;
	reportId: string;
};

type RouteComponentWithAuth0Props = RouteComponentProps & WithAuth0Props;

const reportTypes: { [key: string]: string } = {
	csv: 'CSV',
	ga: 'Google Analytics',
};

class ReportList extends Component<
	RouteComponentWithAuth0Props,
	ReportListState
> {
	private baseApiURL: string = process.env
		.REACT_APP_CAUSAL_IMPACT_API_URL as string;

	constructor(props: RouteComponentWithAuth0Props) {
		super(props);
		this.state = {
			reports: [],
			isLoading: true,
			redirect: false,
			reportId: '',
		};
	}

	componentDidMount() {
		this.refreshReportList();
	}

	private refreshReportList = () => {
		const { auth0 } = this.props;

		this.setState({
			reports: [],
			isLoading: true,
		});

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			axios
				.get(`${this.baseApiURL}/causal-impact/reports/list`, {
					headers: {
						Authorization: `Bearer ${data}`,
					},
				})
				.then((resp) => {
					const reports: Report[] = [];

					resp.data.reports.forEach((report: Report) => {
						reports.push(report);
					});

					this.setState({ reports, isLoading: false });
				})
				.catch(() => {
					this.setState({
						isLoading: false,
					});
				});
		});
	};

	private goToReport = (report_id: string) => {
		this.setState({ redirect: true, reportId: report_id });
	};

	render() {
		const { reports, isLoading, redirect, reportId } = this.state;
		const { location } = this.props;
		const reportRows = reports.map((report) => {
			if (report.status === 'Available') {
				return (
					<tr
						onClick={() => this.goToReport(`${report.report_id}`)}
						style={{ cursor: 'pointer' }}
						key={report.report_id}
					>
						<td>{report.report_name}</td>
						<td>{reportTypes[report.report_type]}</td>
						<td>{report.requester}</td>
						<td>{moment(report.date, 'x').format('MMMM DD YYYY hh:mma')}</td>
						<td>{report.status}</td>
					</tr>
				);
			}

			return (
				<tr key={report.report_id}>
					<td>{report.report_name}</td>
					<td>{reportTypes[report.report_type]}</td>
					<td>{report.requester}</td>
					<td>{moment(report.date, 'x').format('MMMM DD YYYY hh:mma')}</td>
					<td>{report.status}</td>
				</tr>
			);
		});

		if (redirect) {
			return <Redirect push to={`/causal-impact/${reportId}`} />;
		}

		return (
			<div id='causal-impact-container'>
				{location.state !== undefined && (
					<Callout
						style={{ marginBottom: '20px' }}
						intent='success'
						title='Report Request Successful'
					>
						Your report named &quot;{location.state.report_name}&quot; has been
						requested successfully. Please wait a little while as the causal
						impact application processes the report. When the status is marked
						available you can click on the row to view the report.
					</Callout>
				)}
				<div id='causal-impact-header'>
					<H4>Causal Impact: Report List</H4>
					<div>
						<Button
							icon='refresh'
							intent='none'
							style={{ marginRight: '10px' }}
							onClick={() => this.refreshReportList()}
						/>
						<Link to='/causal-impact/new'>
							<Button icon='add' intent='primary' className='add-button'>
								New Report
							</Button>
						</Link>
					</div>
				</div>
				<Card elevation={Elevation.TWO}>
					<HTMLTable id='reports-list-table'>
						<thead>
							<tr>
								<th>Report Name</th>
								<th>Report Type</th>
								<th>Requester</th>
								<th>Date</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>{reportRows}</tbody>
					</HTMLTable>
					{isLoading && (
						<div style={{ margin: '20px 0' }}>
							<Spinner intent='primary' />
						</div>
					)}
					{reports.length === 0 && !isLoading ? (
						<NonIdealState
							icon='timeline-line-chart'
							title='No reports'
							description={`There are no previous Causal Impact reports found. Maybe it's time to run your first report!`}
						/>
					) : (
						''
					)}
				</Card>
			</div>
		);
	}
}

export default withAuth0(ReportList);
