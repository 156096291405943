import React, { Component } from 'react';
import axios from 'axios';
import {
	GoogleLogin,
	GoogleLoginResponse,
	GoogleLoginResponseOffline,
} from 'react-google-login';
import {
	Card,
	Elevation,
	H4,
	Button,
	FormGroup,
	InputGroup,
	HTMLSelect,
	Callout,
	Spinner,
} from '@blueprintjs/core';
import { DateInput, DateRangeInput, DateRange } from '@blueprintjs/datetime';
import { Link, Redirect } from 'react-router-dom';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import * as Validate from 'validate.js';
import CSVReader, { IFileInfo } from '../util/CSVReader';
import isNullItem from '../util/utility';
import AnalyticsSelection from './AnalyticsSelection';
import AnalyticsDimension, { AnalyticsMetric } from './AnalyticsDimension';
import ReportData from './ReportData';

type NewReportState = {
	redirecting: boolean;

	reportType: string;
	reportSubmitting: boolean;

	reportName: string;
	csvFileName: string;

	googleAccessToken: string;

	analyticsAccounts?: AnalyticsSelection[];
	accountsDropdownDisabled: boolean;
	selectedGaAccount?: string;

	analyticsProperties?: AnalyticsSelection[];
	propertiesDropdownDisabled: boolean;
	selectedGaProperty?: string;

	analyticsViews?: AnalyticsSelection[];
	viewsDropdownDisabled: boolean;
	selectedGaView?: string;

	startDate: string;
	endDate: string;
	interventionDate: string;

	controlFilterOperator: string;
	controlFilterOperatorString: string;
	controlFilterChannelGrouping: string;

	variantFilterOperator: string;
	variantFilterOperatorString: string;
	variantFilterChannelGrouping: string;

	reportDimension: string;
	reportMetric: string;

	analyticsDimensions: AnalyticsDimension[];
	currentDimensionMetrics: AnalyticsMetric[];

	formErrors: string[];

	loadingData: boolean;

	csvControlData?: ReportData[];
	csvVariantData?: ReportData[];
};

type FilterOperator = {
	name: string;
	operator: string;
};

const filterOperators: FilterOperator[] = [
	{ name: 'Exact match', operator: '==' },
	{ name: 'Does not match', operator: '!=' },
	{ name: 'Contains substring', operator: '=@' },
	{ name: 'Does not contain substring', operator: '!@' },
];

type ChannelGrouping = {
	name: string;
	expression: string;
};

const channelGroupings: ChannelGrouping[] = [
	{ name: 'All', expression: '' },
	{ name: 'Direct', expression: ';ga:channelGrouping==Direct' },
	{ name: 'Organic Search', expression: ';ga:channelGrouping==Organic Search' },
	{ name: 'Social', expression: ';ga:channelGrouping==Social' },
	{ name: 'Email', expression: ';ga:channelGrouping==Email' },
	{ name: 'Affiliates', expression: ';ga:channelGrouping==Affiliates' },
	{ name: 'Referral', expression: ';ga:channelGrouping==Referral' },
	{ name: 'Paid Search', expression: ';ga:channelGrouping==Paid Search' },
	{
		name: 'Other Advertising',
		expression: ';ga:channelGrouping==Other Advertising',
	},
	{ name: 'Display', expression: ';ga:channelGrouping==Display' },
];

class NewReport extends Component<WithAuth0Props, NewReportState> {
	private baseApiURL: string = process.env
		.REACT_APP_CAUSAL_IMPACT_API_URL as string;

	constructor(props: WithAuth0Props) {
		super(props);
		this.state = {
			redirecting: false,
			reportType: '',
			reportSubmitting: false,
			reportName: '',
			csvFileName: '',
			googleAccessToken: '',
			accountsDropdownDisabled: true,
			propertiesDropdownDisabled: true,
			viewsDropdownDisabled: true,
			startDate: '',
			endDate: '',
			interventionDate: '',
			controlFilterOperator: '',
			controlFilterOperatorString: '',
			controlFilterChannelGrouping: '',
			variantFilterOperator: '',
			variantFilterOperatorString: '',
			variantFilterChannelGrouping: '',
			reportDimension: '',
			reportMetric: '',
			formErrors: [],
			analyticsDimensions: [],
			currentDimensionMetrics: [],
			loadingData: false,
		};
	}

	private validateForm = (): boolean => {
		const conditionCheck = (arr: boolean[]) =>
			arr.every((val) => val === false);

		const {
			reportType,
			reportName,
			selectedGaAccount,
			selectedGaProperty,
			selectedGaView,
			startDate,
			endDate,
			interventionDate,
			reportDimension,
			reportMetric,
			controlFilterOperator,
			controlFilterOperatorString,
			variantFilterOperator,
			variantFilterOperatorString,
			csvControlData,
			csvVariantData,
		} = this.state;

		if (reportType === 'csv') {
			const conditions = [
				reportName === '',
				startDate === '',
				endDate === '',
				interventionDate === '',
				csvControlData === [],
				csvVariantData === [],
			];

			if (conditionCheck(conditions)) {
				if (
					moment(startDate).isAfter(moment()) ||
					moment(endDate).isAfter(moment())
				) {
					return true;
				}

				if (!moment(interventionDate).isBetween(startDate, endDate)) {
					return true;
				}
				return false;
			}

			// if the conditions fail then we disable the button
			return true;
		}

		if (reportType === 'ga') {
			// This seems repetitive but I can't think of a better way to
			// do this right now...
			const conditions = [
				reportName === '',
				selectedGaAccount === '',
				selectedGaProperty === '',
				selectedGaView === '',
				startDate === '',
				endDate === '',
				interventionDate === '',
				reportDimension === '',
				reportMetric === '',
				controlFilterOperator === '',
				controlFilterOperatorString === '',
				variantFilterOperator === '',
				variantFilterOperatorString === '',
			];

			if (conditionCheck(conditions)) {
				if (
					moment(startDate).isAfter(moment()) ||
					moment(endDate).isAfter(moment())
				) {
					return true;
				}

				if (!moment(interventionDate).isBetween(startDate, endDate)) {
					return true;
				}
				return false;
			}
			return true;
		}

		// We shouldn't allow a submission if no report type is set
		return true;
	};

	private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		this.setState<never>({ [event.target.name]: value } as Partial<
			NewReportState
		>);
	};

	private handleSelectChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const { value } = event.currentTarget;
		this.setState<never>({ [event.currentTarget.name]: value } as Partial<
			NewReportState
		>);
	};

	private handleDateRangeChange = (range: DateRange) => {
		const errorMessage =
			'Either your start or end date cannot be in the future.';
		let { formErrors } = this.state;

		if (range[0] !== null && range[1] !== null) {
			const startDate = range[0].toISOString().split('T')[0];
			const endDate = range[1].toISOString().split('T')[0];

			if (
				moment(startDate).isAfter(moment()) ||
				moment(endDate).isAfter(moment())
			) {
				if (!formErrors.includes(errorMessage)) {
					formErrors.push(errorMessage);
				}
			} else if (formErrors.includes(errorMessage)) {
				formErrors = formErrors.filter((msg) => msg !== errorMessage);
			}

			this.setState({
				formErrors,
				startDate,
				endDate,
			});
		}
	};

	private handleDateChange = (date: Date) => {
		const errorMessage =
			'The intervention date must be between the start and end date.';
		let { formErrors } = this.state;
		const { startDate, endDate } = this.state;

		if (date !== null) {
			const interventionDate = date.toISOString().split('T')[0];

			if (!moment(interventionDate).isBetween(startDate, endDate)) {
				if (!formErrors.includes(errorMessage)) {
					formErrors.push(errorMessage);
				}
			} else if (formErrors.includes(errorMessage)) {
				formErrors = formErrors.filter((msg) => msg !== errorMessage);
			}

			this.setState({
				formErrors,
				interventionDate,
			});
		}
	};

	private handleDimensionSelect = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const { analyticsDimensions } = this.state;
		const { name, value } = event.currentTarget;

		analyticsDimensions?.forEach((dimension) => {
			if (dimension.expression === value) {
				this.setState<never>({
					[name]: value,
					currentDimensionMetrics: dimension.metrics,
				} as Partial<NewReportState>);
			}
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleCSVFileInput = (data: any[], file: IFileInfo) => {
		this.setState({
			csvFileName: file.name,
			reportType: 'csv',
			csvControlData: [],
			csvVariantData: [],
			startDate: '',
			endDate: '',
			formErrors: [],
		});

		let startDate = '';
		let endDate = '';

		let { formErrors } = this.state;
		const missingColumnError =
			'You are missing either the date, control, or variant columns in your csv';
		const invalidDateError = 'An invalid date was found in the date column';
		const emptyControlDataError = 'Empty data was found in the control column';
		const emptyVariantDataError = 'Empty data was found in the variant column';
		const missingDaysError =
			'You are missing days of data between your start and end date';
		const notANumberControlDataError =
			'Data was found in the control column that is not a number';
		const notANumberVariantDataError =
			'Data was found in the variant column that is not a number';
		const duplicateDataError = 'Duplicate data was found in your CSV';
		if (file) {
			const controlData: ReportData[] = [];
			const variantData: ReportData[] = [];

			if (
				!('date' in data[0]) ||
				!('control' in data[0]) ||
				!('variant' in data[0])
			) {
				formErrors.push(missingColumnError);
				this.setState({
					formErrors,
				});
				return;
			}

			if (formErrors.includes(missingColumnError)) {
				formErrors = formErrors.filter((msg) => msg !== missingColumnError);
			}

			let lastControlValue;
			let lastVariantValue;
			let lastControlValueCount = 0;
			let lastVariantValueCount = 0;

			for (let i = 0; i < data.length; i += 1) {
				let dateString: string;

				if (typeof data[i].date !== 'string') {
					dateString = String(data[i].date);
				} else {
					dateString = data[i].date;
				}

				if (lastControlValue === data[i].control) {
					lastControlValueCount += 1;
				}

				if (lastVariantValue === data[i].variant) {
					lastVariantValueCount += 1;
				}

				if (lastControlValueCount > 5 || lastVariantValueCount > 5) {
					formErrors.push(duplicateDataError);
					this.setState({
						formErrors,
					});
					return;
				}

				const date = moment(dateString);

				if (!date.isValid()) {
					formErrors.push(invalidDateError);
					this.setState({
						formErrors,
					});
					return;
				}

				if (formErrors.includes(invalidDateError)) {
					formErrors = formErrors.filter((msg) => msg !== invalidDateError);
				}

				const formattedDate = date.format('YYYY-MM-DD');
				if (i === 0) {
					startDate = formattedDate;
				} else if (i === data.length - 1) {
					endDate = formattedDate;
				}

				if (isNullItem(data[i].control)) {
					formErrors.push(emptyControlDataError);
					this.setState({
						formErrors,
					});
					return;
				}

				if (formErrors.includes(emptyControlDataError)) {
					formErrors = formErrors.filter(
						(msg) => msg !== emptyControlDataError
					);
				}

				if (isNullItem(data[i].variant)) {
					formErrors.push(emptyVariantDataError);
					this.setState({
						formErrors,
					});
					return;
				}

				if (formErrors.includes(emptyVariantDataError)) {
					formErrors = formErrors.filter(
						(msg) => msg !== emptyVariantDataError
					);
				}

				if (!Validate.isNumber(data[i].control)) {
					formErrors.push(notANumberControlDataError);
					this.setState({
						formErrors,
					});
					return;
				}

				if (formErrors.includes(notANumberControlDataError)) {
					formErrors = formErrors.filter(
						(msg) => msg !== notANumberControlDataError
					);
				}

				if (!Validate.isNumber(data[i].variant)) {
					formErrors.push(notANumberVariantDataError);
					this.setState({
						formErrors,
					});
					return;
				}

				if (formErrors.includes(notANumberVariantDataError)) {
					// to do: write a utility function that does this for all errors
					// needs more DRY.
					formErrors = formErrors.filter(
						(msg) => msg !== notANumberVariantDataError
					);
				}

				lastControlValue = data[i].control;
				lastVariantValue = data[i].variant;

				controlData.push(new ReportData(formattedDate, data[i].control));
				variantData.push(new ReportData(formattedDate, data[i].variant));
			}

			const daysBetween = moment(endDate).diff(moment(startDate), 'days');
			if (daysBetween + 1 !== controlData.length) {
				if (!formErrors.includes(missingDaysError)) {
					formErrors.push(missingDaysError);
					this.setState({
						formErrors,
					});
				}
				return;
			}

			if (formErrors.includes(missingDaysError)) {
				formErrors = formErrors.filter((msg) => msg !== missingDaysError);
			}

			this.setState({
				formErrors,
				csvFileName: file.name,
				reportType: 'csv',
				csvControlData: controlData,
				csvVariantData: variantData,
				startDate,
				endDate,
			});
		}
	};

	private handleGaAccountSelection = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const { auth0 } = this.props;
		const { googleAccessToken } = this.state;

		const id = event.currentTarget.value;

		this.setState({
			selectedGaAccount: id,
			analyticsProperties: [],
			selectedGaProperty: '',
			propertiesDropdownDisabled: true,
			analyticsViews: [],
			selectedGaView: '',
			viewsDropdownDisabled: true,
		});

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			this.setState({
				loadingData: true,
			});

			axios
				.post(
					`${this.baseApiURL}/google/analytics/account/properties`,
					{
						access_token: googleAccessToken,
						account_id: id,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then((resp) => {
					const properties: AnalyticsSelection[] = [];
					resp.data.properties.forEach((property: AnalyticsSelection) => {
						properties.push(property);
					});

					this.setState({
						loadingData: false,
						analyticsProperties: properties,
						propertiesDropdownDisabled: false,
					});
				})
				.catch(() => {});
		});
	};

	private handleGaPropertySelection = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const { auth0 } = this.props;
		const { googleAccessToken, selectedGaAccount } = this.state;

		const id = event.currentTarget.value;

		this.setState({
			selectedGaProperty: id,
			analyticsViews: [],
			selectedGaView: '',
			viewsDropdownDisabled: true,
		});

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			this.setState({
				loadingData: true,
			});

			axios
				.post(
					`${this.baseApiURL}/google/analytics/account/profiles`,
					{
						access_token: googleAccessToken,
						account_id: selectedGaAccount,
						property_id: id,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then((resp) => {
					const views: AnalyticsSelection[] = [];
					resp.data.profiles.forEach((view: AnalyticsSelection) => {
						views.push(view);
					});

					this.setState({
						loadingData: false,
						analyticsViews: views,
						viewsDropdownDisabled: false,
					});
				})
				.catch(() => {});
		});
	};

	private handleGaViewSelection = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const id = event.currentTarget.value;

		this.setState({
			selectedGaView: id,
		});

		this.getAnalyticsMetadata();
	};

	private getAnalyticsMetadata = () => {
		const { auth0 } = this.props;
		const { googleAccessToken } = this.state;

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			this.setState({
				loadingData: true,
			});

			axios
				.post(
					`${this.baseApiURL}/google/analytics/metadata`,
					{
						access_token: googleAccessToken,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then((resp) => {
					const dimensions: AnalyticsDimension[] = [];
					resp.data.dimensions.forEach((dimension: AnalyticsDimension) => {
						dimensions.push(dimension);
					});

					this.setState({
						loadingData: false,
						analyticsDimensions: dimensions,
					});
				})
				.catch(() => {});
		});
	};

	private googleSignInSuccess = (
		response: GoogleLoginResponse | GoogleLoginResponseOffline
	) => {
		const { auth0 } = this.props;
		if ('code' in response) {
			auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
				this.setState({
					loadingData: true,
				});

				axios
					.post(
						`${this.baseApiURL}/google/oauth/token`,
						{
							access_code: response.code,
						},
						{
							headers: {
								Authorization: `Bearer ${data}`,
							},
						}
					)
					.then((resp) => {
						this.setState({
							loadingData: false,
							reportType: 'ga',
							googleAccessToken: resp.data.access_token,
						});
						this.getAnalyticsAccounts();
					});
			});
		}
	};

	private googleSignInFailure = () => {
		// We should report a failure here...
	};

	private getAnalyticsAccounts = () => {
		const { auth0 } = this.props;
		const { googleAccessToken } = this.state;

		auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
			this.setState({
				loadingData: true,
			});

			axios
				.post(
					`${this.baseApiURL}/google/analytics/account`,
					{
						access_token: googleAccessToken,
					},
					{
						headers: {
							Authorization: `Bearer ${data}`,
						},
					}
				)
				.then((resp) => {
					const accounts: AnalyticsSelection[] = [];
					resp.data.accounts.forEach((account: AnalyticsSelection) => {
						accounts.push(account);
					});

					this.setState({
						loadingData: false,
						analyticsAccounts: accounts,
						accountsDropdownDisabled: false,
					});
				})
				.catch(() => {});
		});
	};

	private submitReport = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.preventDefault();
		const { auth0 } = this.props;
		const { googleAccessToken } = this.state;
		const { reportType, reportName } = this.state;
		if (reportType === 'csv') {
			const {
				startDate,
				endDate,
				interventionDate,
				csvControlData,
				csvVariantData,
			} = this.state;
			auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
				this.setState({
					reportSubmitting: true,
				});

				axios
					.post(
						`${this.baseApiURL}/causal-impact/report/request`,
						{
							report_type: reportType,
							report_name: reportName,
							start_date: startDate,
							end_date: endDate,
							intervention_date: interventionDate,
							control_data: csvControlData,
							variant_data: csvVariantData,
							requester: auth0.user.email,
						},
						{
							headers: {
								Authorization: `Bearer ${data}`,
							},
						}
					)
					.then(() => {
						this.setState({
							reportSubmitting: false,
							redirecting: true,
						});
					})
					.catch(() => {
						this.setState({
							reportSubmitting: false,
						});
					});
			});
		}

		if (reportType === 'ga') {
			const {
				selectedGaAccount,
				selectedGaProperty,
				selectedGaView,
				startDate,
				endDate,
				interventionDate,
				reportDimension,
				reportMetric,
				controlFilterOperator,
				controlFilterOperatorString,
				controlFilterChannelGrouping,
				variantFilterOperator,
				variantFilterOperatorString,
				variantFilterChannelGrouping,
			} = this.state;
			const controlFilter =
				reportDimension +
				controlFilterOperator +
				controlFilterOperatorString +
				controlFilterChannelGrouping;
			const variantFilter =
				reportDimension +
				variantFilterOperator +
				variantFilterOperatorString +
				variantFilterChannelGrouping;

			let controlChannelGrouping = false;
			if (controlFilterChannelGrouping.length > 0) {
				controlChannelGrouping = true;
			}

			let variantChannelGrouping = false;
			if (variantFilterChannelGrouping.length > 0) {
				variantChannelGrouping = true;
			}
			auth0.getAccessTokenSilently({ ignoreCache: true }).then((data) => {
				this.setState({
					reportSubmitting: true,
				});

				axios
					.post(
						`${this.baseApiURL}/causal-impact/report/request`,
						{
							access_token: googleAccessToken,
							report_type: reportType,
							report_name: reportName,
							analytics_account: selectedGaAccount,
							analytics_property: selectedGaProperty,
							analytics_view: selectedGaView,
							start_date: startDate,
							end_date: endDate,
							intervention_date: interventionDate,
							dimension: reportDimension,
							metric: reportMetric,
							control_filter: controlFilter,
							variant_filter: variantFilter,
							control_grouping: controlChannelGrouping,
							variant_grouping: variantChannelGrouping,
							requester: auth0.user.email,
						},
						{
							headers: {
								Authorization: `Bearer ${data}`,
							},
						}
					)
					.then(() => {
						this.setState({
							reportSubmitting: false,
							redirecting: true,
						});
					})
					.catch(() => {
						this.setState({
							reportSubmitting: false,
						});
					});
			});
		}
	};

	render() {
		const {
			redirecting,
			reportName,
			reportType,
			csvFileName,
			reportSubmitting,
			googleAccessToken,
			analyticsAccounts,
			analyticsProperties,
			analyticsViews,
			analyticsDimensions,
			currentDimensionMetrics,
			accountsDropdownDisabled,
			propertiesDropdownDisabled,
			viewsDropdownDisabled,
			controlFilterOperatorString,
			variantFilterOperatorString,
			startDate,
			endDate,
			formErrors,
			loadingData,
		} = this.state;

		if (redirecting) {
			return (
				<Redirect
					to={{
						pathname: '/causal-impact',
						state: { report_name: reportName },
					}}
				/>
			);
		}

		const errorList = formErrors.map((error) => {
			return <li key={error}>{error}</li>;
		});

		const accountsList = analyticsAccounts?.map((account) => {
			return (
				<option key={account.id} value={account.id}>
					{account.name}
				</option>
			);
		});

		const propertiesList = analyticsProperties?.map((property) => {
			return (
				<option key={property.id} value={property.id}>
					{property.name}
				</option>
			);
		});

		const viewsList = analyticsViews?.map((view) => {
			return (
				<option key={view.id} value={view.id}>
					{view.name}
				</option>
			);
		});

		const dimensionsList = analyticsDimensions?.map((dimension) => {
			return (
				<option key={dimension.expression} value={dimension.expression}>
					{dimension.name}
				</option>
			);
		});

		const metricsList = currentDimensionMetrics?.map((metric) => {
			return (
				<option key={metric.expression} value={metric.expression}>
					{metric.name}
				</option>
			);
		});

		const filtersList = filterOperators.map((operator) => {
			return (
				<option key={operator.operator} value={operator.operator}>
					{operator.name}
				</option>
			);
		});

		const channelGroupingList = channelGroupings.map((channelGrouping) => {
			return (
				<option
					key={channelGrouping.expression}
					value={channelGrouping.expression}
				>
					{channelGrouping.name}
				</option>
			);
		});

		return (
			<div id='causal-impact-container'>
				<div id='causal-impact-header'>
					<H4>Causal Impact: New Report</H4>
					<div>
						<Link to='/causal-impact'>
							<Button
								style={{ marginRight: '10px' }}
								intent='none'
								text='Cancel'
							/>
						</Link>
						<Button
							icon='add'
							intent='primary'
							className='add-button'
							loading={reportSubmitting}
							disabled={this.validateForm()}
							onClick={this.submitReport}
						>
							Submit Report
						</Button>
					</div>
				</div>
				<Card elevation={Elevation.TWO}>
					{errorList.length > 0 && (
						<Callout
							style={{ marginBottom: '20px' }}
							title='There are items that require your attention'
							intent='danger'
						>
							<ul id='causal-impact-errors'>{errorList}</ul>
						</Callout>
					)}
					<FormGroup label='Report Name'>
						<InputGroup
							name='reportName'
							intent={reportName === '' ? 'danger' : 'none'}
							onChange={this.handleInputChange}
						/>
					</FormGroup>
					<div id='causal-impact-type-selection'>
						<CSVReader
							onFileLoaded={this.handleCSVFileInput}
							textString={csvFileName}
						/>
						<GoogleLogin
							clientId={process.env.REACT_APP_CAUSAL_IMPACT_CLIENT_ID as string}
							onSuccess={this.googleSignInSuccess}
							onFailure={this.googleSignInFailure}
							accessType='offline'
							responseType='code'
							scope='https://www.googleapis.com/auth/analytics.readonly'
							prompt='consent'
							buttonText='Sign In with Google'
							disabled={googleAccessToken !== ''}
						/>
					</div>
					{reportType === 'csv' && (
						<>
							<FormGroup label='Start Date'>
								{startDate !== '' ? (
									startDate
								) : (
									<span style={{ color: 'red' }}>
										No start date detected. Please make sure your CSV file is
										structured correctly.
									</span>
								)}
							</FormGroup>
							<FormGroup label='End Date'>
								{endDate !== '' ? (
									endDate
								) : (
									<span style={{ color: 'red' }}>
										No end date detected. Please make sure your CSV file is
										structured correctly.
									</span>
								)}
							</FormGroup>
							<FormGroup label='Intervention Date'>
								<DateInput
									formatDate={(date) => date.toDateString()}
									onChange={this.handleDateChange}
									parseDate={(str) => new Date(str)}
								/>
							</FormGroup>
						</>
					)}
					{reportType === 'ga' && (
						<>
							<H4>Google Analytics Properties</H4>
							<FormGroup label='Account'>
								<HTMLSelect
									defaultValue='Select an account...'
									onChange={this.handleGaAccountSelection}
									disabled={accountsDropdownDisabled}
									className='causal-impact-select'
								>
									<option disabled>Select an account...</option>
									{accountsList}
								</HTMLSelect>
							</FormGroup>
							<FormGroup label='Property'>
								<HTMLSelect
									defaultValue='Select a property...'
									onChange={this.handleGaPropertySelection}
									disabled={propertiesDropdownDisabled}
									className='causal-impact-select'
								>
									<option disabled>Select a property...</option>
									{propertiesList}
								</HTMLSelect>
							</FormGroup>
							<FormGroup label='View'>
								<HTMLSelect
									defaultValue='Select a view...'
									onChange={this.handleGaViewSelection}
									disabled={viewsDropdownDisabled}
									className='causal-impact-select'
								>
									<option disabled>Select a view...</option>
									{viewsList}
								</HTMLSelect>
							</FormGroup>
							{dimensionsList.length > 0 && (
								<>
									<H4>Causal Impact Properties</H4>
									<FormGroup label='Date Range'>
										<DateRangeInput
											formatDate={(date) => date.toDateString()}
											onChange={this.handleDateRangeChange}
											parseDate={(str) => new Date(str)}
											shortcuts={false}
										/>
									</FormGroup>
									<FormGroup label='Intervention Date'>
										<DateInput
											formatDate={(date) => date.toDateString()}
											onChange={this.handleDateChange}
											parseDate={(str) => new Date(str)}
											disabled={startDate === '' && endDate === ''}
										/>
									</FormGroup>
									<FormGroup label='Dimension'>
										<HTMLSelect
											defaultValue='Select a dimension...'
											onChange={this.handleDimensionSelect}
											name='reportDimension'
											className='causal-impact-select'
										>
											<option disabled>Select a dimension...</option>
											{dimensionsList}
										</HTMLSelect>
									</FormGroup>
									<FormGroup label='Control Filter'>
										<div className='filter-container'>
											<HTMLSelect
												defaultValue='Select an operator'
												onChange={this.handleSelectChange}
												name='controlFilterOperator'
											>
												<option disabled>Select an operator</option>
												{filtersList}
											</HTMLSelect>
											<InputGroup
												name='controlFilterOperatorString'
												onChange={this.handleInputChange}
												intent={
													controlFilterOperatorString === '' ? 'danger' : 'none'
												}
											/>
											<HTMLSelect
												defaultValue='Default Channel Grouping'
												onChange={this.handleSelectChange}
												name='controlFilterChannelGrouping'
											>
												<option value=''>Default Channel Grouping</option>
												{channelGroupingList}
											</HTMLSelect>
										</div>
									</FormGroup>
									<FormGroup label='Variant Filter'>
										<div className='filter-container'>
											<HTMLSelect
												defaultValue='Select an operator'
												onChange={this.handleSelectChange}
												name='variantFilterOperator'
											>
												<option disabled>Select an operator</option>
												{filtersList}
											</HTMLSelect>
											<InputGroup
												name='variantFilterOperatorString'
												onChange={this.handleInputChange}
												intent={
													variantFilterOperatorString === '' ? 'danger' : 'none'
												}
											/>
											<HTMLSelect
												defaultValue='Default Channel Grouping'
												onChange={this.handleSelectChange}
												name='variantFilterChannelGrouping'
											>
												<option value=''>Default Channel Grouping</option>
												{channelGroupingList}
											</HTMLSelect>
										</div>
									</FormGroup>
									<FormGroup label='Metric'>
										<HTMLSelect
											defaultValue='Select a metric...'
											onChange={this.handleSelectChange}
											name='reportMetric'
											className='causal-impact-select'
											disabled={metricsList.length === 0}
										>
											<option disabled>Select a metric...</option>
											{metricsList}
										</HTMLSelect>
									</FormGroup>
								</>
							)}
						</>
					)}
					{loadingData && <Spinner intent='primary' />}
				</Card>
			</div>
		);
	}
}

export default withAuth0(NewReport);
