import React, { ComponentType } from 'react';
import {
	withAuthenticationRequired,
	withAuth0,
	WithAuth0Props,
} from '@auth0/auth0-react';
import { Card, Elevation, H3, Button, Spinner } from '@blueprintjs/core';
import { Route } from 'react-router-dom';
import BixbyDashboard from './BixbyDashboard';

interface ProtectedRouteProps {
	component: ComponentType;
	path: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

const ProtectedRoute = ({ component, path, ...args }: ProtectedRouteProps) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Route component={withAuthenticationRequired(component)} {...args} />
);

type LoginState = {
	emailAddress: string;
};

class Application extends React.Component<WithAuth0Props, LoginState> {
	constructor(props: WithAuth0Props) {
		super(props);
		this.state = {
			emailAddress: '',
		};
	}

	handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			emailAddress: event.target.value,
		});
	};

	render() {
		const { auth0 } = this.props;
		const { emailAddress } = this.state;

		if (auth0.isLoading) {
			return (
				<div
					style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
				>
					<div style={{ margin: 'auto' }}>
						<Spinner intent='primary' />
					</div>
				</div>
			);
		}

		if (!auth0.isAuthenticated) {
			return (
				<div id='phoenix-container'>
					<Card
						id='phoenix-signin'
						interactive={false}
						elevation={Elevation.TWO}
					>
						<H3>Sign in</H3>
						<p>to continue to Phoenix</p>
						<Button
							className='signin-button'
							onClick={() =>
								auth0.loginWithPopup({
									login_hint: emailAddress,
								})
							}
						>
							Next
						</Button>
					</Card>
				</div>
			);
		}
		return <ProtectedRoute component={BixbyDashboard} path='/' />;
	}
}

export default withAuth0(Application);
