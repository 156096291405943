import { createContext } from 'react';

type GlobalContextProps = {
	authenticated: boolean;
};

const GlobalContext = createContext<GlobalContextProps>({
	authenticated: false,
});

export default GlobalContext;
