import React from 'react';
import {
	Navbar,
	NavbarGroup,
	NavbarDivider,
	Button,
	Popover,
	Classes,
	Position,
	Menu,
	MenuItem,
} from '@blueprintjs/core';
import { withAuth0, WithAuth0Props, useAuth0 } from '@auth0/auth0-react';

function AccountPopover() {
	const { logout } = useAuth0();
	return (
		<Menu>
			<MenuItem text='Settings' icon='settings' disabled />
			<MenuItem
				text='Logout'
				icon='power'
				onClick={() =>
					logout({
						returnTo: process.env.REACT_APP_LOGOUT_URL as string,
					})
				}
			/>{' '}
			{/* what's the best way to do this dynamically since "/" doesn't work? */}
		</Menu>
	);
}

function BixbyNavbar(props: WithAuth0Props) {
	const { auth0 } = props;

	return (
		<Navbar id='bixby-navbar'>
			<NavbarGroup align='right'>
				<Button className={Classes.MINIMAL} icon='notifications' disabled />
				<NavbarDivider />
				<Popover content={<AccountPopover />} position={Position.BOTTOM}>
					<Button
						className={Classes.MINIMAL}
						icon='user'
						text={auth0.user.name}
					/>
				</Popover>
			</NavbarGroup>
		</Navbar>
	);
}

export default withAuth0(BixbyNavbar);
