/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { HTMLTable, H4, Button, Icon } from '@blueprintjs/core';

const ClientSCOPE: React.FunctionComponent = () => (
	<div className='client-tabbed-content'>
		<div className='client-scope-header'>
			<H4>SCOPE</H4>
			<Button text='SCOPE' icon='add' intent='primary' />
		</div>
		<HTMLTable className='client-scope-table'>
			<thead>
				<tr>
					<th>Type</th>
					<th>Added</th>
					<th>Strategy</th>
					<th>Communication</th>
					<th>Organization</th>
					<th>Performance</th>
					<th>Execution</th>
					<th>Notes</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<a href='#'>4/10 Outreach</a>
					</td>
					<td>06/22/2020</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='orange-score'>
							<span className='score-number'>3</span>
						</div>
					</td>
					<td>
						<div className='red-score'>
							<span className='score-number'>1</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<Icon icon='clipboard' />
					</td>
				</tr>
				<tr>
					<td>
						<a href='#'>C-Level Outreach</a>
					</td>
					<td>12/06/2019</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='orange-score'>
							<span className='score-number'>3</span>
						</div>
					</td>
					<td>
						<div className='red-score'>
							<span className='score-number'>1</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<Icon icon='clipboard' />
					</td>
				</tr>
				<tr>
					<td>
						<a href='#'>Compliant Outreach</a>
					</td>
					<td>12/10/2018</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='orange-score'>
							<span className='score-number'>3</span>
						</div>
					</td>
					<td>
						<div className='red-score'>
							<span className='score-number'>1</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<Icon icon='clipboard' />
					</td>
				</tr>
				<tr>
					<td>
						<a href='#'>Delighted Outreach</a>
					</td>
					<td>03/25/2019</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='orange-score'>
							<span className='score-number'>3</span>
						</div>
					</td>
					<td>
						<div className='red-score'>
							<span className='score-number'>1</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<div className='green-score'>
							<span className='score-number'>5</span>
						</div>
					</td>
					<td>
						<Icon icon='clipboard' />
					</td>
				</tr>
			</tbody>
		</HTMLTable>
	</div>
);

export default ClientSCOPE;
