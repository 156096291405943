import React from 'react';
import { Switch, HTMLTable, H4, Button } from '@blueprintjs/core';
import ManagerIcon from './ManagerIcon';

const ClientServices: React.FunctionComponent = () => (
	<div className='client-tabbed-content'>
		<div className='client-scope-header'>
			<H4>Services</H4>
			<Button text='Service' icon='add' intent='primary' />
		</div>
		<HTMLTable className='client-scope-table'>
			<thead>
				<tr>
					<th>Service</th>
					<th>Managers</th>
					<th>Start Date</th>
					<th>Budget</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Client Services</td>
					<td>
						<ManagerIcon name='Mary Bennett' state='green' />
						<ManagerIcon name='Kelsey Ritter' state='red' />
						<ManagerIcon name='Mike Fernandez' state='yellow' />
						<ManagerIcon name='Lina Liu' state='blue' />
					</td>
					<td>07/03/2019</td>
					<td>$10,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>Creative</td>
					<td>
						<ManagerIcon name='Mary Bennett' state='green' />
						<ManagerIcon name='Kelsey Ritter' state='red' />
						<ManagerIcon name='Mike Fernandez' state='yellow' />
						<ManagerIcon name='Lina Liu' state='blue' />
						<ManagerIcon name='Frederic Marks' state='blue' />
						<ManagerIcon name='Dante Rawlings' state='blue' />
					</td>
					<td>07/03/2019</td>
					<td>$9,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>Digital Intelligence</td>
					<td>
						<ManagerIcon name='Mary Bennett' state='green' />
						<ManagerIcon name='Kelsey Ritter' state='red' />
					</td>
					<td>07/03/2019</td>
					<td>$10,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>Paid Social</td>
					<td>
						<ManagerIcon name='Kelsey Ritter' state='red' />
						<ManagerIcon name='Lina Liu' state='blue' />
					</td>
					<td>07/03/2019</td>
					<td>$5,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>PPC</td>
					<td>
						<ManagerIcon name='Dante Rawlings' state='blue' />
						<ManagerIcon name='Mary Bennett' state='green' />
					</td>
					<td>07/03/2019</td>
					<td>$620,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>Programmatic</td>
					<td>
						<ManagerIcon name='Brandon Lam' state='blue' />
					</td>
					<td>07/03/2019</td>
					<td>$620,000</td>
					<td>
						<Switch defaultChecked />
					</td>
				</tr>
				<tr>
					<td>SEO</td>
					<td>
						<ManagerIcon name='Matthew Adams' state='blue' />
					</td>
					<td>07/03/2019</td>
					<td>$3,500</td>
					<td>
						<Switch />
					</td>
				</tr>
			</tbody>
		</HTMLTable>
	</div>
);

export default ClientServices;
