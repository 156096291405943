/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import axios from 'axios';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import { ISubmitEvent, withTheme } from '@rjsf/core';
import { H4, HTMLTable, Button, NonIdealState } from '@blueprintjs/core';
import {
	JSONSchema7,
	JSONSchema7Type,
	JSONSchema7Definition,
} from 'json-schema';
import { Theme as BlueprintFormTheme } from '../util/rjsf/blueprintjs';
import DataConnection from './DataConnection';

const Form = withTheme(BlueprintFormTheme);

interface ClientExternalAccountsProps {
	clientId: string;
}

interface ClientExternalAccountsState {
	schema: JSONSchema7;
	connections: DataConnection[];
}

type SchemaProperties = {
	[key: string]: JSONSchema7Definition;
};

type ClientExternalAccountsPropsWithAuth0 = ClientExternalAccountsProps &
	WithAuth0Props;

class ClientExternalAccounts extends React.Component<
	ClientExternalAccountsPropsWithAuth0,
	ClientExternalAccountsState
> {
	private baseApiURL: string = process.env.REACT_APP_BIXBY_2_API_URL as string;

	constructor(props: ClientExternalAccountsPropsWithAuth0) {
		super(props);
		this.state = {
			schema: {},
			connections: [],
		};
	}

	componentDidMount() {
		const { auth0 } = this.props;
		this.getDataConnections();

		auth0
			.getAccessTokenSilently({ ignoreCache: true })
			.then(() => {
				axios
					.get(`${this.baseApiURL}/datasourcetypes/list/`, {
						headers: {
							Authorization: `Token f2e20082a7fad5836dc33de7e19451da02475d80`, // this is here temporarily, local token
						},
					})
					.then((resp) => {
						const dataSources: JSONSchema7Type[] = [];
						const dataSourceDependencies: JSONSchema7Definition[] = [];
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						resp.data.forEach((dstype: any) => {
							const available_source_ids: any[] = [];
							const available_source_names: any[] = [];
							dstype.available_sources.forEach((e: any) => {
								available_source_ids.push(e.id);
								available_source_names.push(e.name);
							});
							// availableSources = []
							const dataSourceDependency: JSONSchema7Definition = {
								properties: {
									data_source_type: {
										enum: [dstype.display_name],
									},
									available_sources: {
										type: 'string',
										title: 'Data Source Account',
										enum: [...available_source_ids],
										enumNames: [...available_source_names],
									},
									...dstype.connection_props_schema.properties,
								},
								required: ['data_source_type'],
							};
							dataSourceDependencies.push(dataSourceDependency);

							dataSources.push(dstype.display_name);
						});

						const schema: JSONSchema7 = {
							title: 'Add External Account',
							type: 'object',
							required: ['data_source_type'],
							properties: {
								data_source_type: {
									type: 'string',
									title: 'Data Source',
									enum: dataSources,
								},
							},
							dependencies: {
								data_source_type: {
									oneOf: dataSourceDependencies,
								},
							},
						};

						this.setState({
							schema,
						});
					})
					.catch(() => {});
			})
			.catch(() => {});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private clickedSubmit = (event: ISubmitEvent<any>) => {
		console.log(event.formData);
		// eslint-disable-next-line no-param-reassign
		delete event.formData.data_source_type;

		const { auth0, clientId } = this.props;
		auth0
			.getAccessTokenSilently({ ignoreCache: true })
			.then(() => {
				axios
					.post(
						`${this.baseApiURL}/clients/${clientId}/data-connections/`,
						{
							client: `${clientId}`,
							data_source: 1,
							external_id: Math.floor(Math.random() * 10000000 + 1),
							props_json: event.formData,
						},
						{
							headers: {
								Authorization: `Token f2e20082a7fad5836dc33de7e19451da02475d80`,
							},
						}
					)
					.then(() => {
						this.getDataConnections();
					})
					.catch(() => {});
			})
			.catch(() => {});
	};

	private getDataConnections = () => {
		const { auth0, clientId } = this.props;

		auth0.getAccessTokenSilently({ ignoreCache: true }).then(() => {
			axios
				.get(`${this.baseApiURL}/clients/${clientId}/data-connections/`, {
					headers: {
						Authorization: `Token f2e20082a7fad5836dc33de7e19451da02475d80`,
					},
				})
				.then((resp) => {
					const dataConnections: DataConnection[] = [];
					resp.data.forEach((conn: DataConnection) => {
						dataConnections.push(conn);
					});

					this.setState({
						connections: dataConnections,
					});
				})
				.catch(() => {});
		});
	};

	private deleteDataConnection = (connId: number) => {
		const { auth0, clientId } = this.props;
		auth0.getAccessTokenSilently({ ignoreCache: true }).then(() => {
			axios
				.delete(
					`${this.baseApiURL}/clients/${clientId}/data-connections/${connId}/`,
					{
						headers: {
							Authorization: `Token f2e20082a7fad5836dc33de7e19451da02475d80`,
						},
					}
				)
				.then(() => {})
				.catch(() => {});
		});
	};

	render() {
		const { schema, connections } = this.state;
		return (
			<div>
				<H4>External Accounts</H4>
				<HTMLTable style={{ marginBottom: '20px' }}>
					<thead>
						<tr>
							<th>Data Source</th>
							<th>External ID</th>
							<th>Properties</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{connections === [] ? (
							<NonIdealState
								icon='timeline-line-chart'
								title='No reports'
								description='There are no external accounts for this client'
							/>
						) : (
							connections.map((conn) => (
								<tr key={conn.id}>
									<td>
										{conn.data_source === 1
											? 'SEMRush Analytics'
											: conn.data_source}
									</td>
									<td>{conn.external_id}</td>
									<td>
										{Object.entries(conn.props_json).map((entry) => (
											<p>
												{entry[0]}: {entry[1]}
											</p>
										))}
									</td>
									<td>
										<Button
											icon='trash'
											onClick={() => {
												this.deleteDataConnection(conn.id);
											}}
										/>
									</td>
								</tr>
							))
						)}
					</tbody>
				</HTMLTable>
				<Form
					schema={schema}
					onSubmit={this.clickedSubmit}
					className='client-external-account-form'
				/>
			</div>
		);
	}
}

export default withAuth0(ClientExternalAccounts);
