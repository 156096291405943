import React from 'react';
import { Button } from '@blueprintjs/core';
import { ThemeProps, utils } from '@rjsf/core';

import Fields from '../Fields';
import Widgets from '../Widgets';

const { getDefaultRegistry } = utils;
const { fields, widgets } = getDefaultRegistry();

const DefaultChildren = () => (
	<div style={{ marginTop: '20px' }}>
		<Button intent='primary' type='submit'>
			Submit
		</Button>
	</div>
);

const Theme: ThemeProps = {
	children: <DefaultChildren />,
	fields: { ...fields, ...Fields },
	widgets: { ...widgets, ...Widgets },
};

export default Theme;
