import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
// import { Integrations } from '@sentry/tracing';
// import * as Sentry from '@sentry/react';
import history from './util/history';
import Application from './Application';
import GlobalContext from './store/global.context';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: any) => {
	history.replace(appState?.returnTo || window.location.pathname);
};

/* Sentry.init({
	dsn:
		'https://f1851c1b57194819bdd1de69993a2ed8@o49605.ingest.sentry.io/5427813',
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
	environment: 'dev',
}); */

ReactDOM.render(
	<Auth0Provider
		domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
		clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
		redirectUri={window.location.origin}
		audience={process.env.REACT_APP_AUTH0_CAUSAL_INFERENCING_AUDIENCE as string}
		onRedirectCallback={onRedirectCallback}
	>
		<BrowserRouter>
			<GlobalContext.Provider value={{ authenticated: false }}>
				<Application />
			</GlobalContext.Provider>
		</BrowserRouter>
	</Auth0Provider>,
	document.getElementById('root')
);
